<template>
  <div>
    <div>
      <p class="card-label">Enter Card Number</p>
      <input
        class="card-input-box"
        maxlength="16"
        v-model="cardNumber"
        @keydown="IsNumberCheck"
      />
    </div>
    <div>
      <p class="card-label">Enter PIN</p>
      <input
        type="password"
        class="card-input-box"
        maxlength="6"
        v-model="cardPin"
        @keydown="IsNumberCheck"
      />
    </div>
    <button
      class="card-btn"
      @click="handleClick"
    >
      Check Balance
    </button>
  </div>
</template>

<script>
import { IsNumber } from "./../../utils/number-only.utils";

export default {
  name: "BalanceCheck",
  data() {
    return {
      checked: false
    };
  },


  methods: {
    handleClick() {
      let values = {
        cardNumber: this.cardNumber,
        cardPin: this.cardPin,
      };
      this.$emit("handleChecked", values);
    },

    IsNumberCheck(event) {
      IsNumber(event);
    }
  },
};
</script>
<style scoped>
.card-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: #5c5c5c;
}

.card-input-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;
  width: 368px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #DBDADB;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.card-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.5px 16px;
  gap: 10px;
  width: 368px;
  height: 40px;
  left: 314px;
  top: 443px;
  background: var(--primaryColor,#4e3f09);
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: 2rem;
  border: none;
}
</style>
