import axios from 'axios';
import { getBaseUrl } from "./../utils/url.utils";
import { proxyPath } from './../utils';
const url = getBaseUrl();

export const getRedeemSettings = () => {
    return axios.get(
        `https://${url}/ext/${proxyPath}/application/v1/settings/redeem`
    );
}

export const getAccountSettings = () => {
    return axios.get(
        `https://${url}/ext/${proxyPath}/application/v1/settings/account`
    );
}