<template>
  <transition v-if="show" name="modal">
    <div class="qc-modal-mask">
      <div
        class="modal-wrapper"
        v-bind:class="isFaded ? 'fade-in-fwd' : 'scale-up-center'"
      >
        <div class="qc-modal-container">
          <div class="qc-modal-header">
            <div class="modal-title">{{ title }}</div>
            <button class="modal-default-button" @click="$emit('close')">
              <img src="./../../assets/close.svg" alt="close" />
            </button>
          </div>
          <div>
            <slot name="body"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "right-modal",
  props: {
    title: {
      type: String,
      default: "Modal",
    },
    show: {
      type: Boolean,
      default: false,
    },
    isFaded: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show() {
      if (this.show) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    },
  },
};
</script>
<style scoped>
.qc-modal-mask {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  display: unset;
  z-index: 9998;
  width: 100%;
  height: 100%;
}
.prevent-scroll {
  overflow: hidden !important;
}
.qc-modal-container button {
  border: none;
  padding: 10px;
  background: #fff;
}
.qc-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 48px 48px 24px 48px;
  box-shadow: 0px 2px 8px 0px rgba(166, 163, 165, 0.15);
  background: #fff;
}

.modal-title {
  font-family: "PP Object Sans";
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.qc-modal-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.slide-out-right {
  -webkit-animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
}

.modal-default-button {
  float: right;
  cursor: pointer;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.modal-enter .qc-modal-container,
.modal-leave-active .qc-modal-container {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@media only screen and (max-width: 600px) {
  .qc-modal-container {
    position: fixed;
    bottom: 0;
    background: white;
  }
  .qc-modal-mask {
    background: rgba(0, 0, 0, 0.60);
    backdrop-filter: blur(4px);
    align-items: self-end;
    position: fixed;
  }
  .slide-in-right {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
      transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>
