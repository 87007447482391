<template>
  <div v-if='balanceCheckActive'>
    <div class="card-title">Check Card Balance</div>
    <div class='padding-1r'>
      <BalanceCheck @handleChecked="handleChecked" v-if="!isChecked" />
      <CurrentBalanceVue :value="fetchedBalance" v-else/>
    </div>
  </div>
</template>

<script>
import BalanceCheck from "./balanceCheck.vue";
import CurrentBalanceVue from "./currentBalance.vue";
import { balanceEnquiry } from "../../services/qwikcilver-core.service";
import { TransactionTypeId, InputType } from "../../constants/cart.constants";
import { getAccountSettings } from '../../services/settings.service';
import { getSalt } from '../../services/encryption.service';

export default {
  name: "GiftCard",
  data() {
    return {
      isChecked: false,
      cardNumber: "",
      cardPin: "",
      balanceCheckActive:false
    };
  },
  async mounted() {
    await this.isBalanceCheckActive();
  },
  components: {
    BalanceCheck,
    CurrentBalanceVue,
  },
  methods: {
    handleChecked(value) {
      try {
        this.cardNumber = value.cardNumber;
        this.cardPin = value.cardPin;
        this.balanceCheck();
      } catch (error) {
        console.error(error?.message);
      }
    },
     async isBalanceCheckActive() {
      try {
        const response = await getAccountSettings();
        if (response?.data?.data?.active) {
          this.balanceCheckActive = true;
        }
      } catch (error) {
        console.error("ERROR_ISBALANCECHECKACTIVE", error?.message);
      }
    },
    async encryptPayload(payload, publicKey) {
      try {
        // Convert the payload to ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(payload);

        // Import the public key
        const publicKeyObject = await crypto.subtle.importKey(
          "spki",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          false,
          ["encrypt"]
        );

        // Encrypt the data
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          publicKeyObject,
          data
        );

        // Convert encrypted data to base64
        const encryptedDataBase64 = btoa(
          String.fromCharCode(...new Uint8Array(encryptedData))
        );
        console.log("encryptedDataBase64:", encryptedDataBase64);
        return encryptedDataBase64;
      } catch (error) {
        console.error("Encryption error:", error);
        throw error;
      }
    },
    async balanceCheck() {
      try {
        let reqBody = JSON.stringify({
          TransactionTypeId: TransactionTypeId.BALANCE_ENQUIRY,
          InputType: InputType,
          Cards: [
            {
              CardNumber: this.cardNumber,
              CardPin: this.cardPin,
            },
          ],
        });
        let keyResponse = await getSalt();

        const publicKey = new Uint8Array(
          [...atob(keyResponse.data.data.publicKey)].map((char) =>
            char.charCodeAt(0)
          )
        );
        let encryptedPayload = await this.encryptPayload(reqBody, publicKey);
        const response = await balanceEnquiry(
          {
            data: JSON.stringify(encryptedPayload),
          }
        );
        if (response?.data?.data?.balance >= 0) {
          this.isChecked = true;
          this.fetchedBalance = response?.data?.data?.balance;
        }
      } catch (error) {
        console.error(error?.message);
      }
    },
  },
};
</script>
<style>
.card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #3c3131;
}
.padding-1r{
  padding: 1rem;
}
</style>
