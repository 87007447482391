<template>
  <div class="description-container">
    <p class="gift-card-description">
      Gift Cards let you shop your way. Use them for full purchases or save the
      balance for the next time you shop.
    </p>
    <p class="gc-note">
      Please Note : Gift cards are not applicable on orders with products from
      tira treats.
    </p>
    <button @click="handleConfirm" class="mt-1 confirm-btn">Got it</button>
  </div>
</template>
<script>
export default {
  name: "apply-balance",
  components: {},
  props: {},
  data() {
    return {};
  },

  methods: {
    handleConfirm() {
      this.$emit("close");
    },
  },
  computed: {},
};
</script>
<style>
.description-container {
  display: flex;
  flex-direction: column;
}
.description-container .confirm-btn {
  background: #1d1d1d;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  line-height: 160%;
  width: 80%;
  margin: 12px auto;
  padding: 14px 24px 13px 24px;
}
.description-container .gift-card-description {
  color: #211a1e;
  font-family: "PP Object Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 24px 48px;
}
.description-container .gc-note {
  color: #908c8e;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.07px;
  padding: 0 48px 24px 48px;
}
</style>
