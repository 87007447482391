<template>
  <div class="apply-card">
    <div v-if="cardNumber" class="dis-flex flex-dir-col">
      <div class="head">Card Number :</div>
      <div class="value">{{ cardNumber }}</div>
    </div>
    <div v-if="buttonType == 'REMOVE'" class="dis-flex flex-dir-col">
      <div class="head">Card Numbers :</div>
      <div class="tags-main-container">
        <div v-for="card in multiCards" class="card-tags" :key="card">
          <p class="tag-value">{{ card }} </p>
        </div>
      </div>
    </div>
    <div class="dis-flex flex-dir-col">
      <div class="head">
        {{ buttonType == "APPLY" ? "Available" : "Applied" }} Balance :
      </div>
      <div class="value">₹ {{ buttonType == "APPLY" ? balance : totalRedeemBalance }}</div>
    </div>
    <button @click="handleApply" :disabled="disable || isApplyValid ">
      {{ buttonType }}
    </button>
    <p v-if='errorMsg && errorMsg.length' class="red-star error-message">{{errorMsg}}</p>
  </div>
</template>
<script>
import { getCartInfo } from '../../services/cart.service';
import { getCartId, isThisBuyNow } from '../../utils';

export default {
  name: "apply-balance",
  components: {},
  props: {
    cardNumber: {
      default:false
    },
    balance: {},
    buttonType: {
      type: String,
      default: "APPLY",
    },
    apiCall:{
      default : false
    },
    multiCards :[],
    isCancel : {
      default : false
    },
    totalRedeemBalance:{
      default : 0
    },
    fetchedBalance:{
      default : 0
    },
    errorMessage:{
      default:false
    }
  },
  data() {
    return {
      cartData : null,
      cartValue : null,
      disable : false ,
      errorMsg : false,
      cartInfo:false
    };
  },
  watch: {
    apiCall() {
      if (this.apiCall) {
        this.disable = true
      } else {
        this.disable = false
      }
    },
    errorMessage(value){
      if(value){
        this.errorMsg = value;
      }else{
        this.errorMsg=false
      }
    },
  },
  async beforeMount() {
    let cartId = getCartId();
    this.cartInfo = this.buttonType === "APPLY" ? await getCartInfo(cartId,isThisBuyNow()) : [];
    this.cartValue = this.cartInfo?.data?.data?.breakup_values?.raw?.total;
  },
  methods: {
    async handleApply() {
      this.disable = true;
      this.errorMsg = false;

      const value = this.buttonType !== "APPLY";
      
      if (this.buttonType === "APPLY") {
          try {
              const displayValues = this.cartInfo?.data?.data?.breakup_values.display;
              
              const totalInfo = displayValues.find(item => item.display === "Total");

              let total = totalInfo?.value || 0;

              if (total > 1 && !value) {
                  this.$emit("handleApply", value);
              } else {
                  this.disable = false;
                  this.errorMsg = 'You cannot redeem a gift card exceeding the cart value.';
              }
          } catch (error) {
              console.error("Error fetching cart info:", error);
              this.disable = false;
              this.errorMsg = 'An error occurred while applying the gift card.';
          }
      } else {
          this.$emit("handleApply", value);
      }
    }
  },
  computed: {
    /**
     * Computed method to check if type is button and supposed to show
     */
    isApplyValid() {
      return this.balance <= 0 && this.cartValue <= 0;
    },
  },
};
</script>
<style>
.apply-card {
  padding: 24px;
  margin: 13px;
  background: #fff;
  border-radius: 7px;
}
.apply-card .head {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #6d6d6e;
  margin: 0.5rem;
  margin-left: 0;
}

.apply-card .value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #4d4d4e;
  margin: 0.25rem;
  margin-left: 0;
}

.dis-flex {
  display: flex;
}

.flex-dir-col {
  flex-direction: column;
}
.apply-card button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  gap: 10px;
  border: 1px solid var(--buttonPrimary, #1b6163);
  background: var(--buttonPrimary, #1b6163);
  width: 100%;
  cursor: pointer;
  color: white;
  font-weight: 500;
  border: none;
  margin-top: 2rem;
  border-radius: 4px;
}
.card-tags{
  display: flex;
  flex-wrap: wrap; 
}
.tags-main-container{
  max-height: 100px;
  overflow-y: scroll
}
.tag-value{
  display: inline-block;
  padding: 4px 8px;
  margin-right: 8px; 
  margin-bottom: 8px;
  background-color: #f0f0f0;
  color: #333; 
  border-radius: 4px; 
}
.red-star {    
    color: #d93131;
    font-size: 9px;
    font-weight: 900;
}
.error-message{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
</style>
