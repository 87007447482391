import GiftCard from './components/giftcard/giftcard.vue';
import CartRedemption from './components/redeemption/cartRedemption.vue'

window.FPI.extension.register("#dext-account-check-balance", {
  mounted(element) {
    console.log("Account Check Balance Bindings registered");
    window.FPI.extension.mountApp({
      element,
      component: GiftCard,
    });
  },
});

window.FPI.extension.register("#dext-cart-redemption", {
  mounted(element) {
    console.log("Cart Redemption Bindings registered");
    window.FPI.extension.mountApp({
      element,
      component: CartRedemption,
    });
  },
});