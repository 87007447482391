import axios from 'axios';
import { getBaseUrl } from "./../utils/url.utils";
import { proxyPath } from './../utils';
const url = getBaseUrl();


export const balanceEnquiry = (body, cartID="") => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/balance-enquiry?cart_id=${cartID}`,
        body
    );
}

export const checkRedeem = (cartID) => {
    return axios.get(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/check-redeemption?cart_id=${cartID}`
    );
}

export const applyGiftcard = (cartID,body) =>{
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/apply-giftcard?cart_id=${cartID}`,
        body
    );
}
export const removeGiftcard = ({ id, uid }) =>{
    return axios.delete(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/remove-giftcard?id=${id}&uid=${uid}`
    );
}