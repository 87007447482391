<template>
  <Modal :isFaded="true" :show="show">
    <template slot="body">
      <div class="success-modal">
        <div class="success-content">
          <div class="percentage-class">
            <img
              src="./../../assets/percentage.svg"
              alt="percentage"
              width="86px"
              height="86px"
            />
          </div>
          <div class="applied-class">
            ₹ {{ balanceValue }} Card Points Applied
          </div>
          <div class="big-label-off">₹{{ balanceValue }}</div>
          <div class="savings-class">savings with this gift card</div>
        </div>
        <div class="wohoo" @click="handleClose">WOHOOO!!</div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./../modal/Model.vue";
export default {
  name: "success-modal",
  props: {
    balanceValue: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Modal,
  },
  methods: {
    handleClose() {
      this.$emit("closeSucessModal");
    },
  },
};
</script>
<style scoped>
.percentage-class {
  position: absolute;
  top: -16% !important;
  display: block;
}

.applied-class {
  margin-top: 12%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #6d6d6e;
}

.big-label-off {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 140%;
  color: #1f1f1f;
  margin: 0.5rem 0;
}

.wohoo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: var(--buttonPrimary, #1b6163);
  cursor: pointer;
  padding: 20px 0;
}

.savings-class {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #1f1f1f;
}
.success-modal {
  border-radius: 8px;
}

.success-modal .success-content {
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
