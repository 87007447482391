<template>
    <div>
        <div class="currency-title">
            Current Card Balance :
        </div>
        <div class='currency-value'>
            ₹ {{ value }}
        </div>
    </div>
</template>
<script>
export default {
    name: "current-balance",
    props: {
        value: {
            type: Number
        }
    }
}
</script>
<style>
.currency-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #5C5C5C;
    margin-top: 1rem;
}

.currency-value {
    font-family: 'Work Sans';
    font-style: normal;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #3C3131;
    font-weight: 600;
    margin-top: 6.5px;
}
</style>
