<template>
  <div class="check-balance">
    <div class="input-container">
      <label class="label-overlay">
        Enter Card Number <span class="red-star">*</span></label
      >
      <input
        type="text"
        placeholder="Enter your Card Number"
        maxlength="50"
        v-model="cardNumber"
        @keydown="IsNumberCheck"
      />
    </div>
    <div class="input-container">
      <label class="label-overlay"
        >Enter Pin <span class="red-star"> *</span></label
      >
      <input
        type="password"
        placeholder="Enter your PIN number"
        maxlength="25"
        v-model="cardPin"
        @keydown="IsNumberCheck"
      />
    </div>
    <button
      :disabled="!cardPin || !cardNumber || apiCall"
      @click="handleCheck"
      class="mt-1"
    >
      CHECK BALANCE
    </button>
    <div class="red-star error-message" v-if="errorMsg">
      <div>{{errorMsg}}</div>
    </div>
  </div>
</template>
<script>
import { IsNumber } from "./../../utils/number-only.utils";

export default {
  name: "check-balance",
  props:{
    errorMessage:{
      default:false
    },
    apiCall:{
      default:false
    }
  },
  watch:{
    errorMessage(value){
      if(value){
      this.errorMsg = value;
      }else{
        this.errorMsg=false
      }
    },
  },
  components: {},
  data() {
    return {
      cardNumber: "",
      cardPin: "",
      errorMsg:false
    };
  },
  
  methods: {
    handleCheck() {
      let values = { cardNumber: this.cardNumber, cardPin: this.cardPin };
      this.$emit("handleCheck", values);
    },

    IsNumberCheck(event) {
      IsNumber(event);
    },
  },
};
</script>
<style>
.check-balance {
  padding: 14px;
  margin: 13px;
  background: #fff;
  border-radius: 7px;
}
.check-balance input {
  margin: 0 0 1rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px 46px 16px 16px;
}
.error-message{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.check-balance button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;
  font-weight: 600;
  border: 0;
  border-radius: 4px;
}
.check-balance label {
  font-size: 12px;
  line-height: 140%;
  color: #6d6d6e;
}
.mt-1 {
  margin-top: 1rem;
}

.check-balance button:disabled {
  background: var(--buttonPrimary, #dde7e8);
  color: #fff;
  opacity: 0.5;
  border: 0;
  border-radius: 4px;
  cursor: not-allowed;
}

.check-balance button {
  width: 100%;
}
.red-star {
  color: #d93131;
  font-size: 14px;
  font-weight: 600;
}
.check-balance button {
  border: 1px solid var(--buttonPrimary, #1b6163);
  background: var(--buttonPrimary, #1b6163);
  cursor: pointer;
  color: white;
}

.check-balance .input-container {
  position: relative;
}

.check-balance .input-container .label-overlay {
  position: absolute;
  background: #fff;
  left: 10px;
  top: -7px;
  padding: 0 7px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

@media only screen and (max-width: 600px) {
  /* .modal .nitrozen-dialog-backdrop {
    align-items: end;
  }

  .modal .nitrozen-dialog {
    height: 35vh;
  } */
}
</style>
