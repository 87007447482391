<template>
  <div class="check-balance">
    <div class="input-container">
      <label class="label-overlay">
        Enter Card Number <span class="red-star">*</span></label
      >
      <input
        class="input-box"
        type="text"
        v-model="cardNumber"
        @input="validateInput"
        @keydown="IsNumberCheck"
        maxlength="16"
      />
    </div>
    <div class="input-container">
      <label class="label-overlay"
        >Enter Pin <span class="red-star"> *</span></label
      >
      <input
        class="input-box"
        type="password"
        maxlength="6"
        v-model="cardPin"
        @input="validateInput"
        @keydown="IsNumberCheck"
      />
    </div>
    <button
      :disabled="
        !cardPin ||
        !cardNumber ||
        apiCall ||
        cardPin.length < 6 ||
        cardNumber.length < 16
      "
      @click="handleCheck"
      class="mt-1"
    >
      Check Balance
    </button>
    <div
      class="red-star error-message"
      v-if="errorMsg"
    >
      <div>{{ errorMsg }}</div>
    </div>
  </div>
</template>
<script>
import { IsNumber } from "../../utils/number-only.utils";

export default {
  name: "check-balance",
  props: {
    errorMessage: {
      default: false,
    },
    apiCall: {
      default: false,
    },
  },
  watch: {
    errorMessage(value) {
      if (value) {
        this.errorMsg = value;
      } else {
        this.errorMsg = false;
      }
    },
  },
  components: {},
  data() {
    return {
      cardNumber: "",
      cardPin: "",
      errorMsg: false,
    };
  },

  methods: {
    handleCheck() {
      let values = { cardNumber: this.cardNumber, cardPin: this.cardPin };
      this.$emit("handleCheck", values);
    },
    IsNumberCheck(event) {
      IsNumber(event);
    },
    validateInput() {
      this.cardNumber = this.cardNumber.replace(/\D/g, '').slice(0, 16);
      this.cardPin = this.cardPin.replace(/\D/g, '').slice(0, 6);
      if(this.cardNumber.length<16 || this.cardPin.length<6){
        this.errorMsg=null;
      }
    }
  },
};
</script>
<style>
.check-balance {
  padding: 14px;
  margin: 13px;
  background: #fff;
  border-radius: 7px;
}
.check-balance input {
  margin: 0 0 1rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px 46px 16px 16px;
}
.error-message {
  display: flex;
  align-items: center;
  padding: 10px;
}
.check-balance button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;
  font-weight: 600;
  border: 0;
  border-radius: 4px;
}
.check-balance label {
  font-size: 12px;
  line-height: 140%;
  color: #6d6d6e;
}
.mt-1 {
  margin-top: 1rem;
}

.check-balance button:disabled {
  background: var(--buttonPrimary, #dde7e8);
  color: #fff;
  opacity: 0.5;
  border: 0;
  border-radius: 4px;
  cursor: not-allowed;
}

.check-balance button {
  width: 100%;
}
.red-star {
  color: #d93131;
  font-size: 14px;
  font-weight: 600;
}
.check-balance button {
  background: #1d1d1d;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
}

.check-balance .input-container {
  position: relative;
}

.check-balance .input-container .label-overlay {
  font-family: "PP Object Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.check-balance .input-container .input-box {
  border: 1px solid #dbdadb;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  /* .modal .nitrozen-dialog-backdrop {
    align-items: end;
  }

  .modal .nitrozen-dialog {
    height: 35vh;
  } */
}
</style>
